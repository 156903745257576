import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { useContext, useState } from 'react';
import { ColorModeContext, tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { useLanguage, langTokens } from '../../language';

import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// import InputBase from '@mui/material/InputBase';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import SearchIcon from '@mui/icons-material/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Topbar = (props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { language } = useLanguage();
  const strLang = langTokens(language).topbar;
  const navigate = useNavigate();

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };
  const stringAvatar = (name) => {
    if (!name) {
      return {
        sx: {
          bgcolor: '#ccc', // Default background color for empty names
          width: 24,
          height: 24,
          fontSize: '0.8rem',
        },
        children: '?', // Default character for empty names
      };
    }
    const nameParts = name.split(' ');
    let initials;
    if (nameParts.length === 1) {
      initials = `${nameParts[0][0]}`;
    } else {
      initials = `${nameParts[0][0]}${nameParts[1][0]}`;
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 24,
        height: 24,
        fontSize: '0.8rem',
      },
      children: initials,
    };
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOK = () => {
    setOpen(false);
    const _timeRange = localStorage.getItem('timeRange');
    localStorage.clear();
    localStorage.setItem('timeRange', _timeRange);
    if (theme.palette.mode !== 'dark') colorMode.toggleColorMode();
    props.setLoggedIn(false);
    navigate('/');
  };
  // console.log(props.loggedIn);
  return (
    <>
      <Box display='flex' position={'relative'} zIndex={'2'}>
        <IconButton onClick={colorMode.toggleColorMode} sx={{ width: '40px', zIndex: 2 }}>
          {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
        </IconButton>
        {props.loggedIn && (
          <IconButton onClick={handleClickOpen} sx={{ width: '40px', zIndex: 2 }}>
            <Avatar {...stringAvatar(JSON.parse(localStorage.user).username)} />
          </IconButton>
        )}
      </Box>
      {props.loggedIn && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby='alert-confirm-sign-out'
          sx={{
            '& .MuiButton-root': {
              color: colors.grey[100],
              fontWeight: 'bold',
              // border: 'solid 1px ' + colors.greenAccent[500],
            },
          }}>
          <DialogTitle fontWeight={'bold'} fontSize={'110%'}>
            {strLang.SIGN_OUT_TITLE}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-alert-slide-description'>{strLang.SIGN_OUT_CONFIRM}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{strLang.CANCEL_BUTTON}</Button>
            <Button variant='contained' color='success' onClick={handleOK}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Topbar;
