import axios from 'axios';
import { formatISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../language';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import LoginIcon from '@mui/icons-material/LoginOutlined';
import ReloadIcon from '@mui/icons-material/ReplayOutlined';

const Home = (props) => {
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const { language } = useLanguage();
  // const strLang = langTokens(language).home;
  // const { email, loggedIn } = props;

  const navigate = useNavigate();
  const onStartButtonClick = () => {
    if (!props.loggedIn) {
      handleHomeWithOutLoggedIn();
    } else {
      navigate('/dashboard');
    }
  };
  const handleClearCache = () => {
    localStorage.clear();
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const currentDate = now.getDate();

    // Set the desired time range (5 AM to 7 PM)
    const desiredStartTime = new Date(currentYear, currentMonth, currentDate, 5, 0, 0);
    const desiredEndTime = new Date(currentYear, currentMonth, currentDate, 19, 0, 0);

    // Check if the current date is after October 18th
    const cutoffDate = new Date(currentYear, 9, 18, 0, 0, 0); // October 18th
    const isAfterCutoffDate = now >= cutoffDate;

    // Set the time range based on whether the current date is after October 18th
    const timeRange = isAfterCutoffDate
      ? { from: cutoffDate.getTime(), to: new Date(cutoffDate.getTime() + 12 * 60 * 60 * 1000).getTime() }
      : { from: desiredStartTime.getTime(), to: desiredEndTime.getTime() };

    localStorage.setItem('timeRange', JSON.stringify(timeRange));
  };
  const handleHomeWithOutLoggedIn = async () => {
    const apiURL = process.env.REACT_APP_API_LOGIN;
    const loginData = {
      username: process.env.REACT_APP_PUBLIC_USERNAME,
      password: process.env.REACT_APP_PUBLIC_PASSWORD,
    };
    try {
      const response = await axios.get(apiURL, {
        params: {
          json: JSON.stringify(loginData),
        },
      });

      if (response.status === 200) {
        // Public Login successfully, return message
        const dt = response.data;
        localStorage.setItem(
          'user',
          JSON.stringify({ username: 'public', role: dt.role, name: 'public', token: dt.access_token, timestamp: formatISO(new Date()) })
        );
        props.setLoggedIn(false);
        navigate('/dashboard');
        console.log('Public user.');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <Box
      width='100%'
      mt='-68px'
      position={'relative'}
      zIndex={'1'}
      sx={{
        bgcolor: (theme.palette.mode === 'dark' ? '#0a1830' : '#ffffff') + ' !important',
        background: 'url(/assets/bg_' + theme.palette.mode + '.png)',
        backgroundPosition: 'center',
        backgroundSize: '1429px 950px',
      }}>
      <Box height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} position={'relative'}>
        <Button
          variant='contained'
          onClick={onStartButtonClick}
          color='success'
          endIcon={<LoginIcon />}
          sx={{ width: '370px', mt: '310px', height: '47px', color: 'white' }}>
          <Typography variant='h4'>{language === 'en' ? 'Click to Start' : 'Bắt đầu'}</Typography>
        </Button>
        <Box position={'fixed'} bottom='0' left='0'>
          <IconButton aria-label='delete' onClick={handleClearCache}>
            <ReloadIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
