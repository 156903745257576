import Header from '../../components/Header';
import { useLanguage, langTokens } from '../../language';
import { Box } from '@mui/material';

import PhotoGallery from '../../components/PhotoGallery';
const RideSafe = () => {
  const { language } = useLanguage();
  const strLang = langTokens(language).ridesafe;

  // const itemData = [
  //   { id: 1, title: 'Lợi ích khi đi xe đạp' },
  //   { id: 2, title: 'Đạp xe an toàn' },
  //   { id: 3, title: 'Chọn xe an toàn' },
  //   { id: 4, title: 'Chọn xe an toàn (tiếp)' },
  //   { id: 5, title: 'Mũ bảo hiểm & Trang phục' },
  //   { id: 6, title: 'Điều khiển xe an toàn' },
  //   { id: 7, title: 'Điều khiển xe an toàn (tiếp)' },
  //   { id: 8, title: 'Chuyển hướng an toàn' },
  //   { id: 9, title: 'Hành vi nguy hiểm khi đạp xe' },
  //   { id: 10, title: 'Hành vi nguy hiểm khi đạp xe (tiếp)' },
  //   { id: 11, title: 'Một số biển báo cần biết' },
  //   { id: 12, title: 'Câu chuyện: Đạp xe cùng vĩ nhân' },
  // ];
  const imgList = {
    images: [
      'KTDXAT-1',
      'KTDXAT-2',
      'KTDXAT-3',
      'KTDXAT-4',
      'KTDXAT-5',
      'KTDXAT-6',
      'KTDXAT-7',
      'KTDXAT-8',
      'KTDXAT-9',
      'KTDXAT-10',
      'KTDXAT-11',
      'KTDXAT-12',
    ],
    imageType: 'png',
  };
  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Header title={strLang.TITLE} subtitle={strLang.SUBTITLE} />
      </Box>
      <Box display='flex' justifyContent='center'>
        <PhotoGallery imgList={imgList} sideBySide={true} />
      </Box>
    </Box>
  );
};

export default RideSafe;
