import { LayersControl, TileLayer } from 'react-leaflet';

const Basemaps = () => {
  const { BaseLayer } = LayersControl;
  return (
    <>
      <BaseLayer checked name='CartoCDN Streetmap'>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
          //https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png
        />
      </BaseLayer>
      <BaseLayer name='OpenStreetMap'>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
          // tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}.png
          //https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png
        />
      </BaseLayer>
    </>
  );
};

export default Basemaps;
