// src/components/LanguageSwitcher.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../language';
import { Button, useTheme, Typography } from '@mui/material';
import { tokens } from '../theme';

const LanguageSwitcher = (props) => {
  const { language, switchLanguage } = useLanguage();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const onSignInButtonClick = () => {
    if (props.loggedIn) {
      // console.log('Logged in:' + props.loggedIn + '. Remove user - Logout');
      localStorage.clear();
      props.setLoggedIn(false);
    } else {
      // console.log('Logged in:' + props.loggedIn + '. Redirect to - Login');
      navigate('/login');
    }
  };

  return (
    <>
      <Button
        variant='outlined'
        color='success'
        onClick={() => switchLanguage((prev) => (prev === 'en' ? 'vn' : 'en'))}
        sx={{ px: '10px', width: '66px', height: 28, borderRadius: 23, zIndex: 2 }}>
        <img
          alt={language === 'en' ? 'lang-en' : 'lang-vn'}
          width='16'
          height='12'
          src={'/assets/' + (language === 'en' ? 'en' : 'vn') + '.svg'}
          style={{ cursor: 'pointer', marginRight: '8px' }}
        />
        <Typography variant='bold10' color={theme.palette.mode === 'dark' ? 'white' : 'black'}>
          {language === 'en' ? 'ENG' : 'VIE'}
        </Typography>
      </Button>
      {!props.loggedIn && (
        <Button
          variant='outlined'
          onClick={onSignInButtonClick}
          color='success'
          sx={{
            bgcolor: colors.greenAccent[400] + '33',
            width: '100px !important',
            height: '28px',
            borderRadius: '23px',
            ml: '6px',
            px: '10px',
            zIndex: 2,
          }}>
          <Typography variant='bold10' color={theme.palette.mode === 'dark' ? 'white' : 'black'}>
            {language === 'en' ? 'Log in' : 'Đăng nhập'}
          </Typography>
        </Button>
      )}
    </>
  );
};

export default LanguageSwitcher;
