import React, { useEffect, useRef } from 'react';
import { MapContainer, LayersControl, LayerGroup } from 'react-leaflet';
import Box from '@mui/material/Box';

// import 'leaflet/dist/leaflet.css';
import { useLanguage } from '../../language';
import L from 'leaflet';
import viLocale from 'date-fns/locale/vi';
import { format } from 'date-fns';
import HeatmapLayerFactory from '@vgrid/react-leaflet-heatmap-layer/cjs/HeatmapLayer';
import Basemaps from './Basemaps.jsx';
import SchoolMarker from './SchoolMarker.jsx';
import { phuThienCoord as phuThien } from '../../data/geoCoding.js';

const Heatmapv4 = ({ fullArray, quarterIndex, showSchools, overlaysEnabled }) => {
  // console.log(dataArray);
  const plotOpacity = 1;
  const maxValue = fullArray ? fullArray.totalDevices / 20 : 30;
  const { language } = useLanguage();
  const { Overlay } = LayersControl;
  const mapRef = useRef();
  const HeatmapLayer = HeatmapLayerFactory();
  // const dataArray = initData ? initData.filteredObj : null;
  const quarters = ['05:00-08:00', '10:00-12:00', '12:00-14:00', '16:00-19:00'];
  const currentSchool = localStorage.getItem('currentSchool') ? JSON.parse(localStorage.getItem('currentSchool')) : null;
  // const dataArray =
  //   quarterIndex === null
  //     ? simpleArray
  //     : fullArray !== null
  //     ? fullArray.filteredObj
  //     : JSON.parse(localStorage.getItem('initData'))?.filteredObj;
  // const isAllSchool = currentSchool?.id === 0 ? true : false;
  const dataArray = fullArray !== null ? fullArray.filteredObj : JSON.parse(localStorage.getItem('initData'))?.filteredObj;
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Custom hook to fit the map bounds to all polylines
  // const FitBoundsPolylines = ({ polylines }) => {
  //   const map = useMap();
  //   // console.log(polylines);
  //   useEffect(() => {
  //     if (polylines.length > 0) {
  //       const bounds = L.latLngBounds(polylines.flat());
  //       map.fitBounds(bounds);
  //     }
  //   }, [polylines, map]);

  //   return null;
  // };

  // const combinedData = (data) => {
  //   // console.log(data);
  //   const _combinedObj = data ? {} : null;
  //   Object.keys(data).forEach((day) => {
  //     const _dayData = data[day];
  //     Object.keys(_dayData).forEach((quarter) => {
  //       if (!_combinedObj[quarter]) {
  //         _combinedObj[quarter] = [];
  //       }
  //       const _quarterData = _dayData[quarter];
  //       Object.keys(_quarterData).forEach((deviceId) => {
  //         _quarterData[deviceId].forEach((e) => {
  //           _combinedObj[quarter].push([e[0], e[1]]);
  //         });
  //       });
  //     });
  //   });
  //   return _combinedObj;
  // };
  const combinedLatLongData = (data) => {
    const _combinedObj = [];
    if (data)
      Object.keys(data).forEach((deviceId) => {
        data[deviceId].forEach((e) => {
          _combinedObj.push([e[0], e[1]]);
        });
      });
    return _combinedObj;
  };

  // const _combinedData = combinedData(dataArray);

  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          Lat: school[2],
          Lon: school[3],
          address: school[4],
          // ward: `${subarea.name} (${subarea.id})`,
          // district: `${area.name} (${area.id})`,
          // city: `${city.name} (${city.id})`,
          // startDate: school[8], // Initialize start and end dates
          // endDate: school[9],
        }));
      })
    )
  );
  const flyToLocation = (latitude, longitude, zoomLevel) => {
    const map = mapRef.current;
    map.flyTo([latitude, longitude], zoomLevel);
  };
  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      if (currentSchool !== null) {
        flyToLocation(currentSchool.Lat, currentSchool.Lon, currentSchool?.id > 0 ? 14 : 12);
      }
    }
  }, [currentSchool]);
  return (
    <Box width={'100%'} height={'100%'} id='map'>
      <MapContainer
        ref={mapRef}
        center={phuThien.center}
        zoom={14}
        minZoom={12}
        maxZoom={18}
        maxBoundsViscosity={1.0}
        maxBounds={
          JSON.parse(localStorage.getItem('user')).username === process.env.REACT_APP_SUPER_ADMIN
            ? null
            : L.latLngBounds(phuThien.sW, phuThien.nE)
        }
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}>
        <LayersControl position='topright'>
          {
            dataArray !== null &&
              dataArray !== undefined &&
              // quarterIndex !== null &&
              // Object.keys(_combinedData)
              //   .sort()
              //   .map((key, index) => (
              //     <Overlay name={'Khung giờ ' + key} key={index} checked>
              //       <HeatmapLayer
              //         fitBoundsOnLoad
              //         fitBoundsOnUpdate
              //         points={_combinedData[key]}
              //         longitudeExtractor={(point) => parseFloat(point[1])}
              //         latitudeExtractor={(point) => parseFloat(point[0])}
              //         intensityExtractor={(point) => parseFloat(1)}
              //         max={maxValue}
              //         radius={4} //default radius 30
              //         blur={5} //default blur 15
              //         opacity={plotOpacity}
              //         key={index}
              //       />
              //     </Overlay>
              //   )))

              Object.keys(dataArray).map((day) => {
                // day: e.g. 2024-09-15
                const quarter = quarters[quarterIndex - 1];
                const dataArray1 = dataArray[day][quarter];
                // const dayOfWeek = format(new Date(day), 'e');
                // console.log('dayOfWeek', format(new Date(day), 'e'), format(new Date(day), 'eee'));
                const dateTimeLabel = format(new Date(day), 'eee, dd/LL/yy', language !== 'en' ? { locale: viLocale } : '');
                return (
                  <Overlay name={dateTimeLabel} key={day} checked={overlaysEnabled}>
                    <LayerGroup>
                      <HeatmapLayer
                        // fitBoundsOnLoad={!isAllSchool}
                        // fitBoundsOnUpdate={!isAllSchool}
                        points={combinedLatLongData(dataArray1)}
                        longitudeExtractor={(point) => parseFloat(point[1])}
                        latitudeExtractor={(point) => parseFloat(point[0])}
                        intensityExtractor={(point) => parseFloat(1)}
                        max={maxValue}
                        radius={3} //default radius 30
                        blur={2} //default blur 15
                        opacity={plotOpacity}
                        key={day}
                      />
                    </LayerGroup>
                  </Overlay>
                );
              })
            //   )
            // ) : (
            //   <></>
            // )
          }

          {/* <Overlay name={'Các trường THCS'} key={'schools'} checked>
            <LayerGroup>
              {schools.map((school, idx) => (
                <SchoolMarker key={idx} position={[school.Lat, school.Lon]} address={school.address} name={school.schoolName} />
              ))}
            </LayerGroup>
          </Overlay> */}
          <Overlay name={language === 'en' ? "Schools' location" : 'Vị trí các trường THCS'} key={'schools'} checked={showSchools}>
            <LayerGroup>
              {currentSchool?.id > 0 ? (
                <SchoolMarker
                  position={[currentSchool.Lat, currentSchool.Lon]}
                  address={currentSchool.address}
                  name={currentSchool.schoolName}
                />
              ) : (
                schools.map((school) => (
                  <SchoolMarker key={school.id} position={[school.Lat, school.Lon]} address={school.address} name={school.schoolName} />
                ))
              )}
            </LayerGroup>
          </Overlay>
          <Basemaps />
        </LayersControl>
        {/* <FitBoundsPolylines polylines={Object.values(_combinedLatLong)} /> */}
      </MapContainer>
    </Box>
  );
};

export default Heatmapv4;
