// // calculateBoundingRectangle.js

// function toRadians(degrees) {
//   return degrees * (Math.PI / 180);
// }

// function toDegrees(radians) {
//   return radians * (180 / Math.PI);
// }

// function midpoint(lat1, lon1, lat2, lon2) {
//   const dLon = toRadians(lon2 - lon1);

//   lat1 = toRadians(lat1);
//   lat2 = toRadians(lat2);
//   lon1 = toRadians(lon1);

//   const Bx = Math.cos(lat2) * Math.cos(dLon);
//   const By = Math.cos(lat2) * Math.sin(dLon);
//   const latMid = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By));
//   const lonMid = lon1 + Math.atan2(By, Math.cos(lat1) + Bx);

//   return [toDegrees(latMid), toDegrees(lonMid)];
// }

// function destinationPoint(lat, lon, distance, bearing) {
//   const R = 6371e3;
//   const angularDistance = distance / R;
//   const bearingRad = toRadians(bearing);

//   const latRad = toRadians(lat);
//   const lonRad = toRadians(lon);

//   const lat2 = Math.asin(
//     Math.sin(latRad) * Math.cos(angularDistance) + Math.cos(latRad) * Math.sin(angularDistance) * Math.cos(bearingRad)
//   );

//   const lon2 =
//     lonRad +
//     Math.atan2(
//       Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(latRad),
//       Math.cos(angularDistance) - Math.sin(latRad) * Math.sin(lat2)
//     );

//   return [toDegrees(lat2), toDegrees(lon2)];
// }

// function bearing(lat1, lon1, lat2, lon2) {
//   const dLon = toRadians(lon2 - lon1);
//   lat1 = toRadians(lat1);
//   lat2 = toRadians(lat2);

//   const y = Math.sin(dLon) * Math.cos(lat2);
//   const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
//   return (toDegrees(Math.atan2(y, x)) + 360) % 360;
// }

// function distanceBetween(lat1, lon1, lat2, lon2) {
//   const R = 6371e3;
//   const phi1 = toRadians(lat1);
//   const phi2 = toRadians(lat2);
//   const deltaPhi = toRadians(lat2 - lat1);
//   const deltaLambda = toRadians(lon2 - lon1);

//   const a =
//     Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
//     Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//   return R * c;
// }

// function calculateRectangle(lat1, lon1, lat2, lon2, width) {
//   const [midLat, midLon] = midpoint(lat1, lon1, lat2, lon2);
//   const angle = bearing(lat1, lon1, lat2, lon2);

//   const perpAngle1 = (angle + 90) % 360;
//   const perpAngle2 = (angle - 90 + 360) % 360;

//   const halfWidth = width / 2;

//   const cornerMid1 = destinationPoint(midLat, midLon, halfWidth, perpAngle1);
//   const cornerMid2 = destinationPoint(midLat, midLon, halfWidth, perpAngle2);

//   const corner1 = destinationPoint(cornerMid1[0], cornerMid1[1], distanceBetween(lat1, lon1, lat2, lon2) / 2, angle);
//   const corner2 = destinationPoint(cornerMid1[0], cornerMid1[1], distanceBetween(lat1, lon1, lat2, lon2) / 2, (angle + 180) % 360);
//   const corner3 = destinationPoint(cornerMid2[0], cornerMid2[1], distanceBetween(lat1, lon1, lat2, lon2) / 2, angle);
//   const corner4 = destinationPoint(cornerMid2[0], cornerMid2[1], distanceBetween(lat1, lon1, lat2, lon2) / 2, (angle + 180) % 360);

//   return [corner1, corner2, corner3, corner4];
// }

// // Function to calculate the rectangle for each central line in the array
// function calculateBoundingRectangles(roads, width) {
//   return roads.map((road) => {
//     const [lat1, lon1] = road.central[0];
//     const [lat2, lon2] = road.central[1];

//     const rectangleCorners = calculateRectangle(lat1, lon1, lat2, lon2, width);

//     return {
//       road: road.road,
//       corners: rectangleCorners,
//     };
//   });
// }

// export default calculateBoundingRectangles;
// calculateBoundingRectangles.js

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function toDegrees(radians) {
  return radians * (180 / Math.PI);
}

function destinationPoint(lat, lon, distance, bearing) {
  const R = 6371e3; // Earth's radius in meters
  const angularDistance = distance / R;
  const bearingRad = toRadians(bearing);

  const latRad = toRadians(lat);
  const lonRad = toRadians(lon);

  const lat2 = Math.asin(
    Math.sin(latRad) * Math.cos(angularDistance) + Math.cos(latRad) * Math.sin(angularDistance) * Math.cos(bearingRad)
  );

  const lon2 =
    lonRad +
    Math.atan2(
      Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(latRad),
      Math.cos(angularDistance) - Math.sin(latRad) * Math.sin(lat2)
    );

  return [toDegrees(lat2), toDegrees(lon2)];
}

function bearing(lat1, lon1, lat2, lon2) {
  const dLon = toRadians(lon2 - lon1);
  lat1 = toRadians(lat1);
  lat2 = toRadians(lat2);

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  return (toDegrees(Math.atan2(y, x)) + 360) % 360;
}

// Function to calculate the bounding rectangle given two GPS points and a width
function calculateRectangle(lat1, lon1, lat2, lon2, width) {
  const angle = bearing(lat1, lon1, lat2, lon2);

  const halfWidth = width / 2;

  // Calculate the two points perpendicular to the central line at point 1
  const point1a = destinationPoint(lat1, lon1, halfWidth, angle + 90);
  const point1b = destinationPoint(lat1, lon1, halfWidth, angle - 90);

  // Calculate the two points perpendicular to the central line at point 2
  const point2a = destinationPoint(lat2, lon2, halfWidth, angle + 90);
  const point2b = destinationPoint(lat2, lon2, halfWidth, angle - 90);

  // Return the four corners of the rectangle
  return [point1a, point1b, point2b, point2a];
}

// Function to calculate the rectangle for each central line in the array
export function calculateBoundingRectangles(roads, width) {
  // console.log(roads);
  return roads.map((road) => {
    const rectangleCorners = calculateRectangle(road.lat1, road.long1, road.lat2, road.long2, width);
    // console.log(rectangleCorners);
    return {
      road: road.road,
      corners: rectangleCorners,
    };
  });
}

export default calculateBoundingRectangles;

export function isPointInRectangle(point, rectangleCorners) {
  const [lat, lon] = [point[0], point[1]];

  let inside = false;
  for (let i = 0, j = rectangleCorners.length - 1; i < rectangleCorners.length; j = i++) {
    const [latI, lonI] = rectangleCorners[i];
    const [latJ, lonJ] = rectangleCorners[j];
    // eslint-disable-next-line
    const intersect = lonI > lon !== lonJ > lon && lat < ((latJ - latI) * (lon - lonI)) / (lonJ - lonI) + latI;
    if (intersect) inside = !inside;
  }

  return inside;
}
export const countIntersections = (routes, roads) => {
  const width = 30;
  const rectangles = calculateBoundingRectangles(roads, width);
  // console.log('rectangles', rectangles);
  const intersectionCount = {};
  let maxIntersectionCount = 0;
  rectangles.forEach((rect) => {
    intersectionCount[rect.road] = {
      count: 0,
      routeIndices: [],
    }; // Initialize the count and array for each road
    // console.log('routes:', routes);
    // eslint-disable-next-line
    Object.keys(routes).map((key, routeIndex) => {
      let hasIntersection = false;
      const route = routes[key]; //key = device ID

      // console.log('route:', route);
      for (let i = 0; i < route.length; i++) {
        const point = route[i];
        // console.log(point);
        if (isPointInRectangle(point, rect.corners)) {
          hasIntersection = true;
          break; // Stop checking further points in this route if one intersects
        }
      }

      if (hasIntersection) {
        intersectionCount[rect.road].count += 1;
        intersectionCount[rect.road].routeIndices.push(routeIndex);
        maxIntersectionCount = Math.max(maxIntersectionCount, intersectionCount[rect.road].count);
      }
    });
    // intersectionCount.total = totalIntersectionCount;
  });
  // console.log(maxIntersectionCount);
  intersectionCount.maxCount = maxIntersectionCount;
  // console.log(intersectionCount);
  return intersectionCount;
};
