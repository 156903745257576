import { Box, useTheme, Typography } from '@mui/material';
import Header from '../../components/Header';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { tokens } from '../../theme';
import { useLanguage, langTokens } from '../../language';

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { language } = useLanguage();
  const strLang = langTokens(language).faq;

  const faqs = [
    {
      id: 1,
      question: 'Làm thế nào để sử dụng app ?',
      answer: 'Bạn chỉ cần vào mục Tổng hợp, chọn Lựa chọn dữ liệu theo Địa điểm và chọn Địa điểm mình quan tâm.',
    },
    {
      id: 2,
      question: 'Ai có thể sử dụng app ?',
      answer: 'Bất kỳ ai quan tâm đến những thông tin được cung cấp tại app đều có thể sử dụng.',
    },
  ];
  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Header title={strLang.TITLE} subtitle={strLang.SUBTITLE} />
      </Box>
      <Box width={'100%'}>
        {faqs.map((faq) => {
          return (
            <Accordion key={faq.id} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color={colors.greenAccent[400]} variant='h5' fontWeight={'bold'}>
                  {faq.id} - {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default FAQ;
