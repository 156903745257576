import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { tokens } from '../../theme';
import { useLanguage, langTokens } from '../../language';
import { useNavigate } from 'react-router-dom';
import SessionExpiredDialog from '../../components/SessionExpiredDialog.jsx';
import Header from '../../components/Header';

import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
// import PasswordIcon from '@mui/icons-material/KeyOutlined';
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';

const roles = ['admin', 'user'];
const initialRows = [
  {
    id: 0,
    name: '',
    user: '',
    pass: '',
    email: '',
    role: 'User',
  },
];

const Team = (props) => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const strLang = langTokens(language).team;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const _isAdmin = JSON.parse(localStorage.getItem('user')).role === 'admin' ? true : false;
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
    props.setLoggedIn(false);
    navigate('/login');
  };
  const handleAPIerror = (err) => {
    if (err.response.status === 401) {
      console.log('Error 401 !!!');
      localStorage.removeItem('user');
      setOpenAlertDialog(true);
    } else {
      console.error(err.response.status, err.response.message);
    }
  };
  const EditToolbar = (props) => {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      //assign new id to the new row
      const id = 0;
      setRows((oldRows) => [...oldRows, { id, name: '', age: '', role: 'user', isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };

    return (
      <GridToolbarContainer sx={{ backgroundColor: 'antiquewhite', p: 0 }}>
        <Button
          color='primary'
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleClick}
          sx={{
            m: '3px',
            border: 'solid 1px ' + colors.greenAccent[700],
            bgcolor: colors.greenAccent[600] + '66',
            '&:hover': {
              bgcolor: colors.greenAccent[600] + 'cc',
            },
          }}>
          <Typography pt='2px' fontSize={'12px'} fontWeight={'bold'}>
            {strLang.ADD_NEW}
          </Typography>
        </Button>
      </GridToolbarContainer>
    );
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
      field: 'name',
      headerName: strLang.NAME,
      width: 200,
      cellClassName: 'name-column--cell',
      editable: true,
    },
    { field: 'user', headerName: strLang.USERNAME, width: 150, editable: true },
    {
      field: 'newPassword',
      headerName: strLang.PASSWORD,
      width: 150,
      editable: true,
      renderCell: (params) => {
        if (params.row.isEditing) {
          return '';
        } else {
          return '***';
        }
      },
    },
    { field: 'email', headerName: 'Email', width: 300, editable: true },
    {
      field: 'role',
      headerName: strLang.ACCESS_LEVEL,
      headerAlign: 'center',
      width: 150,
      padding: '0 22px !important',
      editable: true,
      type: 'singleSelect',
      valueOptions: roles,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width='100%'
            m='0 auto'
            p='5px 0'
            display='flex'
            alignItems='center'
            justifyContent='center'
            backgroundColor={role === 'admin' ? colors.greenAccent[600] : colors.greenAccent[800]}
            borderRadius='4px'>
            {role === 'admin' && <AdminPanelSettingsOutlinedIcon style={{ fontSize: '15px' }} />}
            {role === 'user' && <LockOpenOutlinedIcon style={{ fontSize: '15px' }} />}
            <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: strLang.ACTIONS,
      width: 200,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label='Save' className='textPrimary' onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />,
        ];
      },
    },
  ];

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = useState(initialRows);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  useEffect(() => {
    const getAllUsers = async () => {
      const apiURL = process.env.REACT_APP_API_GET_ALL_USERS;
      try {
        const response = await axios.get(apiURL, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
          },
        });

        if (response.status === 200) {
          const filteredData = !_isAdmin
            ? response.data.filter((user) => user.user === JSON.parse(localStorage.getItem('user')).username)
            : response.data.filter((user) => user.user !== 'public_user');

          const processedData = filteredData.map((row) => {
            return {
              ...row, // Copy all existing properties
              newPassword: '', // Add an empty `newPassword` field
            };
          });
          setRows(processedData);
        }
      } catch (error) {
        handleAPIerror(error);
      }
    };

    getAllUsers();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    // console.log(editedRow);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      showAlert('Cancel create. No new user created !', 'info');
    } else {
      showAlert('Cancel update. Values are not modified !', 'info');
    }
  };
  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const isNew = newRow.id === 0 ? true : false;
    // console.log(newRow);
    // updatedRow
    // {
    //   "email": "vandung.dao@ioteamvn.com",
    //   "id": 1,
    //   "name": "admin",
    //   "pass": "$2b$12$ujGq8BsVt9bm6aU0RiL.gOKiL3NNN.hc/SfsyRUfnSfNimQUaAmpu",
    //   "role": "user",
    //   "user": "admin",
    //   "isNew": false
    // }

    const apiURL = isNew ? process.env.REACT_APP_API_CREATE_USER : process.env.REACT_APP_API_UPDATE_USER;
    const updateData = {
      username: updatedRow.user,
      password: updatedRow.newPassword,
      role: updatedRow.role,
      name: updatedRow.name,
      email: updatedRow.email,
      id: updatedRow.id,
    };
    if (isNew) delete updateData.id; //Do not include ID if this is a new User
    if (!isNew && updateData.password === '') delete updateData.password; //Do not update password if no change.

    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
        },
        params: {
          json: JSON.stringify(updateData),
        },
      });
      // if CREATE new user and the response status is 201 OR
      // if UPDATE auser and the response status is 200 THEN return OK !

      if (isNew && response.status === 201) {
        console.log('New row: ', updatedRow.id);
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        showAlert('New row added successfully!', 'success');
        return updatedRow;
      } else if (!isNew && response.status === 200) {
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        showAlert('Row updated successfully!', 'success');
        return updatedRow;
      } else if (response.status === 401) {
        console.log(response.status, response.message);
        setOpenAlertDialog(true);
      } else {
        console.error('Error updating data:', response.statusText);
        showAlert('Error updating data:', response.statusText, 'error');
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  const showAlert = (message, severityLevel) => {
    setOpenAlert(true);
    setAlertMessage(message);
    setSeverity(severityLevel); //severityLevel = 'success', 'info', 'warning' or 'error'
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  return (
    <>
      <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
        <Box display='flex' justifyContent='flex-start' alignItems='center'>
          <Header title={strLang.TITLE} subtitle={strLang.SUBTITLE} />
        </Box>
        <Box
          mt='20px'
          height='calc(100vh - 140px)'
          alignItems='center'
          sx={{
            width: 'calc(100% - 20px)',
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
              fontSize: '14px !important',
            },

            '& .MuiDataGrid-editInputCell > input ': {
              padding: '0 10px !important',
              fontSize: '14px !important',
            },
            '& .MuiSelect-select': {
              padding: '8px 14px',
              textAlign: 'center',
              width: '80%',
            },
            '& .MuiDataGrid-cell:last-of-type': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: `${colors.greenAccent[700]} !important`,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '13px',
              fontWeight: 'bold !important',
              textTransform: 'uppercase',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '.MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.greenAccent[700],
            },
            '& .MuiCheckbox-root': {
              color: `${colors.grey[200]} !important`,
            },
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}>
          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert severity={severity} variant='filled'>
              {alertMessage}
            </Alert>
          </Snackbar>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: _isAdmin ? EditToolbar : undefined,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            localeText={{
              noRowsLabel: language === 'en' ? 'No row' : 'Không có dữ liệu',
              MuiTablePagination: {
                labelRowsPerPage: language === 'en' ? 'Rows per page:' : 'Số hàng mỗi trang:',
              },
            }}
          />
        </Box>
      </Box>
      <SessionExpiredDialog open={openAlertDialog} onClose={handleCloseAlertDialog} loggedIn={props.loggedIn} language={language} />
    </>
  );
};

export default Team;
/* <Header title='TEAM' subtitle='Managing the Team Members' /> */
