import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { tokens } from '../../theme';
import { useLanguage, langTokens } from '../../language';
import { useNavigate } from 'react-router-dom';
import SessionExpiredDialog from '../../components/SessionExpiredDialog.jsx';
import Header from '../../components/Header';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
// import PasswordIcon from '@mui/icons-material/KeyOutlined';
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
// import { UpdateRounded } from '@mui/icons-material';

const Roads = (props) => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const strLang = langTokens(language).roads;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          coordinate: `[${school[2]}, ${school[3]}]`,
          address: school[4],
          ward: subarea.name,
          district: area.name,
          city: city.name,
          startDate: school[8], // Initialize start and end dates
          endDate: school[9],
        }));
      })
    )
  );

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
    props.setLoggedIn(false);
    navigate('/login');
  };
  const handleAPIerror = (err) => {
    console.log(err);
    if (err.response.status === 401) {
      console.log('Error 401 !!!');
      localStorage.removeItem('user');
      setOpenAlertDialog(true);
    } else {
      console.error(err.response.status, err.response.message);
    }
  };
  const EditToolbar = (props) => {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      //assign new id to the new row
      const id = 0;
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          roadName: '',
          Lat1: '',
          Lat2: '',
          Long1: '',
          Long2: '',
          schoolID: selectedSchoolId,
          plot: null,
          isNew: true,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'roadName' },
      }));
    };

    return (
      <GridToolbarContainer sx={{ backgroundColor: 'antiquewhite', p: 0 }}>
        <Button
          color='primary'
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleClick}
          sx={{
            m: '3px',
            border: 'solid 1px ' + colors.greenAccent[700],
            bgcolor: colors.greenAccent[600] + '66',
            '&:hover': {
              bgcolor: colors.greenAccent[600] + 'cc',
            },
          }}>
          <Typography pt='2px' fontSize={'12px'} fontWeight={'bold'}>
            {strLang.ADD_NEW}
          </Typography>
        </Button>
      </GridToolbarContainer>
    );
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 80, hideable: true },
    { field: 'schoolID', headerName: strLang.SCHOOL, width: 100 },
    { field: 'roadName', headerName: strLang.NAME, width: 200, cellClassName: 'name-column--cell', editable: true, flex: 0.5 },
    { field: 'Lat1', headerName: strLang.LAT1, width: 100, editable: true },
    { field: 'Lat2', headerName: strLang.LAT2, width: 100, editable: true },
    { field: 'Long1', headerName: strLang.LONG1, width: 100, editable: true },
    { field: 'Long2', headerName: strLang.LONG2, width: 100, editable: true },
    { field: 'plot', headerName: strLang.PLOT, width: 400, editable: true, flex: 1 },
    { field: 'cou', headerName: strLang.COUNT, headerAlign: 'center', width: 100, editable: true, cellClassName: 'textCenter' },
    { field: 'ratio', headerName: strLang.RATIO, headerAlign: 'center', width: 100, editable: true, cellClassName: 'textCenter' },
    { field: 'color', headerName: strLang.COLOR, headerAlign: 'center', width: 100, editable: true, cellClassName: 'textCenter' },
    {
      field: 'actions',
      type: 'actions',
      headerName: strLang.ACTIONS,
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label='Save' className='textPrimary' onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />,
        ];
      },
    },
  ];

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  //đã check đến đây ok !

  const [selectedSchoolId, setSelectedSchoolId] = useState('');

  useEffect(() => {
    if (selectedSchoolId) {
      // Call API to fetch related data using selectedSchoolId
      fetchRelatedData(selectedSchoolId);
    }
  }, [selectedSchoolId]);

  const fetchRelatedData = async (schoolId) => {
    const apiURL = process.env.REACT_APP_API_GET_ROADS;
    const _params = { schoolID: schoolId };
    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
        },
        params: {
          json: JSON.stringify(_params),
        },
      });
      // console.log(response.data);
      const processedData = response.data.road.map((road) => {
        return {
          id: road[0],
          roadName: road[1],
          Lat1: road[2],
          Lat2: road[3],
          Long1: road[4],
          Long2: road[5],
          schoolID: road[6],
          plot: road[7],
          cou: road[8],
          ratio: road[9],
          color: road[10],
          status: road[11],
        };
      });
      setRows(processedData);
    } catch (error) {
      handleAPIerror(error);
    }
  };

  // useEffect(() => {
  //   const getRoads = async () => {
  //     const apiURL = process.env.REACT_APP_API_GET_ROADS;
  //     try {
  //       const response = await axios.get(apiURL, {
  //         headers: {
  //           Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
  //         },
  //       });

  //       if (response.status === 200) {
  //         // const filteredData = response.data.filter((user) => user.user !== 'public_user');
  //         setRows(response.data);
  //       }
  //     } catch (error) {
  //       handleAPIerror(error);
  //     }
  //   };
  //   getRoads();
  // }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    // console.log(editedRow);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      showAlert('Cancel create. No new road created !', 'info');
    } else {
      showAlert("Cancel update. Road's values are not modified !", 'info');
    }
  };
  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const isNew = newRow.id === 0 ? true : false;
    console.log(updatedRow);
    console.log('isNew', isNew);
    if (updatedRow.plot !== null && updatedRow.plot.replace(/\s/g, '') === '') updatedRow.plot = null;
    console.log(updatedRow);
    const apiURL = process.env.REACT_APP_API_GET_ROADS;
    const updateData = {
      type: isNew ? 'insert' : 'update',
      ...updatedRow,
    };
    console.log('isNew', isNew);
    if (isNew) updateData.schoolID = selectedSchoolId;
    console.log(updateData);
    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
        },
        params: {
          json: JSON.stringify(updateData),
        },
      });
      if (isNew && response.status === 200) {
        showAlert('New row added successfully!', 'success');
        fetchRelatedData(selectedSchoolId);
        return;
      } else if (!isNew && response.status === 200) {
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        showAlert('Row updated successfully!', 'success');
        return updatedRow;
      }
    } catch (error) {
      handleAPIerror(error);
    }
  };
  const showAlert = (message, severityLevel) => {
    setOpenAlert(true);
    setAlertMessage(message);
    setSeverity(severityLevel); //severityLevel = 'success', 'info', 'warning' or 'error'
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  return (
    <>
      <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
        <Box display='flex' justifyContent='flex-start' alignItems='center'>
          <Header title={strLang.TITLE} subtitle={strLang.SUBTITLE} />
        </Box>
        <Box
          mt='10px'
          height='calc(100vh - 192px)'
          alignItems='center'
          sx={{
            width: '100%',
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
              fontSize: '14px !important',
            },

            '& .MuiDataGrid-editInputCell > input ': {
              padding: '0 10px !important',
              fontSize: '14px !important',
            },
            '& .textCenter > .MuiDataGrid-editInputCell > input ': {
              textAlign: 'center',
            },
            '& .MuiSelect-select': {
              padding: '8px 14px',
              textAlign: 'center',
              width: '80%',
            },
            '& .MuiDataGrid-cell:last-of-type': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: `${colors.greenAccent[700]} !important`,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '13px',
              fontWeight: 'bold !important',
              textTransform: 'uppercase',
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '.MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.greenAccent[700],
            },
            '& .MuiCheckbox-root': {
              color: `${colors.grey[200]} !important`,
            },
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.greenAccent[300], // Replace 'blue' with your desired focused color
            },
            '& .MuiOutlinedInput-root.Mui-focused:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray', // Set the default color here
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: colors.greenAccent[300], // Change the label font color when focused
            },
            '& .MuiCheckbox-root.Mui-checked:not(.Mui-disabled)': {
              color: colors.greenAccent[300], // Change the label font color when focused
            },
            '& .MuiCheckbox-root.Mui-checked:not(.Mui-disabled) ~ .MuiFormControlLabel-label': {
              fontWeight: 'bold',
              color: colors.greenAccent[300], // Change the label font color when focused
            },
            '& .textCenter': {
              textAlign: 'center !important',
            },
          }}>
          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert severity={severity} variant='filled'>
              {alertMessage}
            </Alert>
          </Snackbar>
          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mb={'1em'}>
            <Typography fontSize={'14px'} ml='4px' pt='2px'>
              <u>{strLang.CHOOSE_SCHOOL_INVITE}:</u>
            </Typography>
            <FormControl variant='outlined' sx={{ m: 1, minWidth: 280 }} size='small'>
              <InputLabel id='choose-school-select-label'>{strLang.CHOOSE_SCHOOL}</InputLabel>
              <Select
                value={selectedSchoolId}
                onChange={(e) => setSelectedSchoolId(e.target.value)}
                labelId='choose-school-select-label'
                id='choose-school-select'
                label={strLang.CHOOSE_SCHOOL}
                autoWidth>
                {schools.map((school) => (
                  <MenuItem key={school.id} value={school.id}>
                    {school.id} - {school.schoolName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns id and schoolID, the other columns will remain visible
                  id: false,
                },
              },
            }}
            localeText={{
              noRowsLabel: language === 'en' ? 'No row' : 'Không có dữ liệu',
              MuiTablePagination: {
                labelRowsPerPage: language === 'en' ? 'Rows per page:' : 'Số hàng mỗi trang:',
              },
            }}
          />
        </Box>
      </Box>
      <SessionExpiredDialog open={openAlertDialog} onClose={handleCloseAlertDialog} loggedIn={props.loggedIn} language={language} />
    </>
  );
};

export default Roads;
/* <Header title='TEAM' subtitle='Managing the Team Members' /> */
