import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useLanguage, langTokens } from '../../language';

import { DataGrid } from '@mui/x-data-grid';
import Header from '../../components/Header';

const Campaign = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { language } = useLanguage();
  const strLang = langTokens(language).campaign;

  const columns = [
    { field: 'id', headerName: strLang.SCHOOL_ID, width: 100 },
    { field: 'schoolName', headerName: strLang.SCHOOL_NAME, width: 220, cellClassName: 'name-column--cell' },
    { field: 'coordinate', headerName: 'Coordinates', width: 150 },
    { field: 'address', headerName: strLang.SCHOOL_ADDRESS, width: 350, flex: 1 },
    { field: 'ward', headerName: strLang.WARD, width: 220 },
    { field: 'district', headerName: strLang.DISTRICT, width: 150 },
    { field: 'city', headerName: strLang.CITY, width: 150, headerAlign: 'center', cellClassName: 'centerText--cell' },
    {
      field: 'startDate',
      headerName: strLang.START,
      headerAlign: 'center',
      width: 130,
      editable: true,
      cellClassName: 'centerText--cell',
    },
    {
      field: 'endDate',
      headerName: strLang.END,
      headerAlign: 'center',
      width: 130,
      editable: true,
      cellClassName: 'centerText--cell',
    },
  ];

  const processedData = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          coordinate: `[${school[2]}, ${school[3]}]`,
          address: school[4],
          ward: subarea.name,
          subarea_ID: subarea.id,
          district: area.name,
          district_id: area.id,
          city: city.name,
          city_id: city.id,
          startDate: school[8], // Initialize start and end dates
          endDate: school[9],
        }));
      })
    )
  );
  // console.log(processedData);
  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Header title={strLang.TITLE} subtitle={strLang.SUBTITLE} />
      </Box>
      <Box
        mt='20px'
        height='calc(100vh - 140px)'
        alignItems='center'
        sx={{
          width: 'calc(100% - 20px)',
          '&. .even-row': { bgcolor: colors.grey[100] },
          '&. .odd-row': { bgcolor: colors.grey[200] },
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
            fontSize: '14px !important',
          },

          '& .MuiDataGrid-editInputCell > input ': {
            padding: '0 10px !important',
            fontSize: '14px !important',
          },
          '& .MuiSelect-select': {
            padding: '8px 14px',
            textAlign: 'center',
            width: '80%',
          },
          '& .MuiDataGrid-cell:last-of-type': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
            fontWeight: 'bold',
          },
          '& .centerText--cell': {
            textAlign: 'center !important',
            justifyContent: 'center !important',
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: `${colors.greenAccent[700]} !important`,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '13px',
            fontWeight: 'bold !important',
            textTransform: 'uppercase',
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 1,
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '.MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.greenAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.grey[200]} !important`,
          },
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}>
        <DataGrid
          rows={processedData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10]}
          rowClassName={(params) => {
            return params.index % 2 === 0 ? 'even-row' : 'odd-row';
          }}
          localeText={{
            noRowsLabel: language === 'en' ? 'No row' : 'Không có dữ liệu',
            MuiTablePagination: {
              labelRowsPerPage: language === 'en' ? 'Rows per page:' : 'Số hàng mỗi trang:',
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns id and schoolID, the other columns will remain visible
                coordinate: false,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
export default Campaign;
