import React, { useEffect, useRef } from 'react';
import { MapContainer, LayersControl, LayerGroup, useMap } from 'react-leaflet';
import Box from '@mui/material/Box';

// import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import HeatmapLayerFactory from '@vgrid/react-leaflet-heatmap-layer/cjs/HeatmapLayer';
import Basemaps from './Basemaps';
import SchoolMarker from './SchoolMarker';
import { phuThienCoord as phuThien } from '../../data/geoCoding.js';

const Heatmapv3 = ({ initData }) => {
  // console.log(dataArray);
  const plotOpacity = 1;
  const maxValue = 30 || initData?.totalDevices;
  const { Overlay } = LayersControl;
  const mapRef = useRef();
  const HeatmapLayer = HeatmapLayerFactory();
  const dataArray = initData ? initData.filteredObj : null;

  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Custom hook to fit the map bounds to all polylines
  const FitBoundsPolylines = ({ polylines }) => {
    const map = useMap();
    // console.log(polylines);
    useEffect(() => {
      if (polylines.length > 0) {
        const bounds = L.latLngBounds(polylines.flat());
        map.fitBounds(bounds);
      }
    }, [polylines, map]);

    return null;
  };

  const combinedData = (data) => {
    // console.log(data);
    const _combinedObj = data ? {} : null;
    Object.keys(data).forEach((day) => {
      const _dayData = data[day];
      Object.keys(_dayData).forEach((quarter) => {
        if (!_combinedObj[quarter]) {
          _combinedObj[quarter] = [];
        }
        const _quarterData = _dayData[quarter];
        Object.keys(_quarterData).forEach((deviceId) => {
          _quarterData[deviceId].forEach((e) => {
            _combinedObj[quarter].push([e[0], e[1]]);
          });
        });
      });
    });
    return _combinedObj;
  };

  const _combinedData = combinedData(dataArray);

  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          Lat: school[2],
          Lon: school[3],
          address: school[4],
          // ward: `${subarea.name} (${subarea.id})`,
          // district: `${area.name} (${area.id})`,
          // city: `${city.name} (${city.id})`,
          // startDate: school[8], // Initialize start and end dates
          // endDate: school[9],
        }));
      })
    )
  );
  return (
    <Box width={'100%'} height={'100%'} id='map'>
      <MapContainer
        center={phuThien.center}
        zoom={13}
        minZoom={12}
        maxZoom={18}
        maxBoundsViscosity={1.0}
        maxBounds={
          JSON.parse(localStorage.getItem('user')).username === process.env.REACT_APP_SUPER_ADMIN
            ? null
            : L.latLngBounds(phuThien.sW, phuThien.nE)
        }
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}>
        <LayersControl position='topright'>
          {Object.keys(_combinedData)
            .sort()
            .map((key, index) => (
              <Overlay name={'Khung giờ ' + key} key={index} checked>
                <HeatmapLayer
                  fitBoundsOnLoad
                  fitBoundsOnUpdate
                  points={_combinedData[key]}
                  longitudeExtractor={(point) => parseFloat(point[1])}
                  latitudeExtractor={(point) => parseFloat(point[0])}
                  intensityExtractor={(point) => parseFloat(1)}
                  max={maxValue}
                  radius={4} //default radius 30
                  blur={5} //default blur 15
                  opacity={plotOpacity}
                  key={index}
                />
              </Overlay>
            ))}

          <Overlay name={'Các trường THCS'} key={'schools'} checked>
            <LayerGroup>
              {schools.map((school, idx) => (
                <SchoolMarker key={idx} position={[school.Lat, school.Lon]} address={school.address} name={school.schoolName} />
              ))}
            </LayerGroup>
          </Overlay>
          <Basemaps />
        </LayersControl>
        {/* <FitBoundsPolylines polylines={Object.values(_combinedLatLong)} /> */}
      </MapContainer>
    </Box>
  );
};

export default Heatmapv3;
