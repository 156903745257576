import { Box } from '@mui/material';

import { useLanguage, langTokens } from '../../language';

import Header from '../../components/Header';
import PhotoGallery from '../../components/PhotoGallery';
const Info = () => {
  const { language } = useLanguage();
  const strLang = langTokens(language).info;
  const imgList = { images: ['info_page_1', 'info_page_2'], imageType: 'png' };

  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Header title={strLang.TITLE} subtitle={strLang.SUBTITLE} />
      </Box>
      <Box display='flex' justifyContent='center'>
        <PhotoGallery imgList={imgList} />
      </Box>
    </Box>
  );
};

export default Info;
