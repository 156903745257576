import { formatISO } from 'date-fns';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLanguage, langTokens } from '../../language';
import {
  Box,
  Button,
  Typography,
  TextField,
  useTheme,
  InputAdornment,
  InputLabel,
  FormControl,
  IconButton,
  FilledInput,
  FormHelperText,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/LoginOutlined';
import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import PasswordIcon from '@mui/icons-material/Password';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/CloseOutlined';

const Login = (props) => {
  const theme = useTheme();
  const { language } = useLanguage();
  const strLang = langTokens(language).login;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const closeForm = () => {
    navigate('/home');
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const onButtonClick = () => {
    // console.log('Login button clicked');
    //set initial error values to empty
    setUsernameError('');
    setPasswordError('');
    // check if the user has entered both fields correctly
    if ('' === username) {
      setUsernameError(strLang.ERROR_USERNAME);
      return;
    }
    if ('' === password) {
      setPasswordError(strLang.ERROR_PASSWORD);
      return;
    }

    authenticateUser({ username, password });
  };

  // axios.defaults.withCredentials = true; // Enable cookie handling

  const authenticateUser = async ({ username, password }) => {
    const apiURL = process.env.REACT_APP_API_LOGIN;

    const loginData = {
      username: username.trim(),
      password: password.trim(),
    };

    try {
      const response = await axios.get(apiURL, {
        params: {
          json: JSON.stringify(loginData),
        },
      });

      if (response.status === 200) {
        // Login successful, return message
        const dt = response.data;
        localStorage.removeItem('user');
        localStorage.setItem(
          'user',
          JSON.stringify({ username, role: dt.role, name: dt.name, token: dt.access_token, timestamp: formatISO(new Date()) })
        );
        props.setLoggedIn(true);
        navigate('/dashboard');
      } else if (response.status !== 200) {
        // Login failed
        setErrorMessage(strLang.ERROR_401 || response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage(strLang.ERROR_401 || error.response.data.message);
      } else {
        setErrorMessage(strLang.ERROR_GENERAL);
      }
      // console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      width='100%'
      mt='-68px'
      sx={{
        backgroundColor: (theme.palette.mode === 'dark' ? '#0a1830' : '#ffffff') + ' !important',
        background: 'url(/assets/bg_' + theme.palette.mode + '.png)',
        backgroundPosition: 'center',
        backgroundSize: '1429px 950px',
      }}>
      <Box height={'100vh'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Box
          width='405px'
          height='340px'
          sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#0a1830' : '#ffffff', borderRadius: '50%' }}>
          <Box mt='10px' display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <img style={{ height: '118px' }} src={'/assets/bikesafely_logo.svg'} alt='Bike Safely app' />
            <img
              style={{ height: '38px', marginTop: '18px' }}
              src={'/assets/bikesafely_' + theme.palette.mode + '.svg'}
              alt='Bike Safely title'
            />

            <Box
              m='15px 15px 0 0'
              width='370px'
              backgroundColor={theme.palette.mode === 'dark' ? '#091730' : '#ffffff'}
              p='0 60px'
              component='form'
              sx={{ '& > :not(style)': { m: '0.5em 0', width: 'calc(100%)' } }}
              noValidate
              autoComplete='off'>
              <TextField
                error={usernameError !== '' ? true : false}
                helperText={usernameError}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                id='user-standard'
                label={strLang.USER}
                variant='filled'
                color='success'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl error={passwordError !== '' ? true : false} sx={{ m: 1, width: '100%' }} variant='filled' color='success'>
                <InputLabel htmlFor='filled-adornment-password'>{strLang.PASS}</InputLabel>
                <FilledInput
                  id='filled-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={togglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position='start'>
                      <PasswordIcon />
                    </InputAdornment>
                  }
                />
                <FormHelperText id='filled-adornment-password-error-text'>{passwordError}</FormHelperText>
              </FormControl>
              {errorMessage && (
                <Typography color='error' variant='body2'>
                  {errorMessage}
                </Typography>
              )}
              <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} alignItems={'center'} gap={'1em'}>
                <Button onClick={closeForm} color='secondary' endIcon={<CloseIcon />}>
                  <strong style={{ color: theme.palette.mode === 'dark' ? '#4cceac' : '#112d5e' }}>{strLang.CANCEL}</strong>
                </Button>
                <Button
                  onClick={onButtonClick}
                  variant='contained'
                  color='success'
                  endIcon={<LoginIcon style={{ color: 'white' }} />}
                  disabled={isLoading}
                  width={'50%'}>
                  <strong style={{ color: 'white' }}>{isLoading ? 'Logging in...' : strLang.SIGNIN}</strong>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Login;
