import { useState, useRef } from 'react';
// import { tokens } from '../theme';
// import { useLanguage, langTokens } from '../language';
import { Box, IconButton, Card, CardContent, CardMedia } from '@mui/material';
import PrevIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import NextIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const PhotoGallery = ({ imgList, sideBySide }) => {
  const images = imgList.images;
  const imageType = imgList.imageType;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageRef = useRef(null);
  const _sideBySide = sideBySide || false;

  const handleNextImage = () => {
    if (_sideBySide) setCurrentImageIndex((prevIndex) => (prevIndex + 2) % images.length);
    else setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    if (_sideBySide) setCurrentImageIndex((prevIndex) => (prevIndex - 2 + images.length) % images.length);
    else setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleImageClick = (event) => {
    const imageWidth = imageRef.current.offsetWidth;
    const clickX = event.clientX - imageRef.current.getBoundingClientRect().left;

    if (clickX < imageWidth / 2) {
      // Clicked on the left half
      handlePreviousImage();
    } else {
      // Clicked on the right half
      handleNextImage();
    }
  };

  return (
    <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} m={'0 1em'}>
      <IconButton aria-label='previous' onClick={handlePreviousImage}>
        <PrevIcon />
      </IconButton>
      <Card
        sx={{
          boxShadow: 0,
          background: 'none transparent !important',
          '& .MuiCardContent-root': { p: 0 },
          '& .MuiCardContent-root:last-child': { pb: 0 },
        }}>
        <CardContent>
          {!_sideBySide && (
            <CardMedia
              component='img'
              alt='Gallery Image'
              sx={{ height: 'calc(100vh - 135px)' }}
              image={`/assets/${images[currentImageIndex]}.${imageType}`} // Adjust extension as needed
              onClick={handleImageClick}
              ref={imageRef}
            />
          )}
          {_sideBySide && (
            <Box display={'flex'} justifyContent={'space-around'} width={'100%'}>
              <CardMedia
                component='img'
                alt='Gallery Image'
                sx={{ height: 'calc(100vh - 135px)' }}
                image={`/assets/${images[currentImageIndex]}.${imageType}`} // Adjust extension as needed
                onClick={handlePreviousImage}
              />
              <CardMedia
                component='img'
                alt='Gallery Image'
                sx={{ height: 'calc(100vh - 135px)' }}
                image={`/assets/${images[currentImageIndex + 1]}.${imageType}`} // Adjust extension as needed
                onClick={handleNextImage}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      <IconButton aria-label='next' onClick={handleNextImage}>
        <NextIcon />
      </IconButton>
    </Box>
  );
};

export default PhotoGallery;
