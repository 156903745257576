import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const SessionExpiredDialog = ({ open, onClose, loggedIn, language }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ width: '600px', m: '0 auto' }}>
      <DialogTitle sx={{ textTransform: 'uppercase', fontSize: '125%', textAlign: 'center' }} id='alert-dialog-title'>
        <InfoIcon color='success' sx={{ fontSize: '2em', mb: '0.25em' }} />
        <br />
        {'Phiên làm việc đã kết thúc'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' sx={{ m: '0 auto', width: '200px', textAlign: 'center' }}>
          Để tiếp tục, vui lòng bấm Đồng ý để {loggedIn ? 'Đăng nhập lại' : 'bắt đầu lại.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: '0 auto', fontSize: '120%' }}>
        <Button color='success' variant='outlined' onClick={onClose} autoFocus>
          Đồng ý
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpiredDialog;
