import { useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useLocation } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';
import { useLanguage, langTokens } from '../../language';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RoadIcon from '@mui/icons-material/RoundaboutRightOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem active={selected === title} style={{ color: colors.grey[100] }} onClick={() => setSelected(title)} icon={icon}>
      <Typography fontSize={'13px'}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const theme = useTheme();
  const themeColor = theme.palette.mode;
  const colors = tokens(themeColor);
  const { language } = useLanguage();
  const strLang = langTokens(language).sidebar;
  const [isCollapsed, setIsCollapsed] = useState(false);
  let currentSelected = useLocation().pathname.replace('/', '');
  if (currentSelected === 'ridesafe') currentSelected = 'KNOWLEDGE';
  // console.log(strLang[currentSelected.toUpperCase()]);
  const [selected, setSelected] = useState(strLang[currentSelected.toUpperCase()]);
  const isNotHomeOrLogin = !props.isHome;
  const user = props.loggedIn ? JSON.parse(localStorage.getItem('user')) : undefined;
  return (
    <>
      {isNotHomeOrLogin && (
        <Box
          sx={{
            '& .pro-sidebar-inner': {
              bgcolor: `${colors.primary[400]} !important`,
            },
            '& .pro-icon-wrapper': {
              bgcolor: 'transparent !important',
              marginRight: '2px !important',
            },
            '& .pro-inner-item': {
              padding: '2px 20px 8px 10px !important;',
            },
            '& .pro-inner-item:hover': {
              color: colors.greenAccent[400] + '!important;', //colors.greenAccent[400] - old: #868dfb
            },
            '& .pro-menu-item.active': {
              color: colors.greenAccent[300] + '!important;', //colors.greenAccent[400] - old: #868dfb
            },
            '& .collapsed': {
              width: '55px',
              minWidth: '55px !important',
            },
          }}>
          <ProSidebar collapsed={isCollapsed} width={'215px'}>
            <Menu iconShape='square'>
              <MenuItem
                onClick={() => setIsCollapsed(!isCollapsed)}
                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                style={{ margin: '10px 0 20px 0', color: colors.grey[100] }}>
                {!isCollapsed && (
                  <Box display='flex' justifyContent='space-between' alignItems='center' ml='15px' pl='10px'>
                    <Box width='125px' minWidth='125px' height={'18px'}>
                      <img style={{ height: '18px' }} src={'/assets/bikesafely_' + theme.palette.mode + '.svg'} alt='Bike Safely app' />
                    </Box>
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <MenuOutlinedIcon />
                    </IconButton>
                  </Box>
                )}
              </MenuItem>
              {!isCollapsed && (
                <Box mb='25px'>
                  <Box display='flex' justifyContent='center' alignItems='center'>
                    <img alt='app-logo' height='50px' src={'/assets/bikesafely_logo.svg'} style={{ marginLeft: '10px' }} />
                  </Box>
                  {props.loggedIn && (
                    <Box textAlign='center'>
                      <Typography fontSize={'14px'} color={colors.greenAccent[500]} m='10px 0 0'>
                        {strLang.WELCOME}
                      </Typography>
                      <Typography fontSize={'17px'} color={colors.grey[100]} fontWeight='bold' textTransform='capitalize'>
                        {user.name}
                      </Typography>
                      <Typography fontSize={'12px'} color={colors.greenAccent[500]} p='4px 0'>
                        (
                        {user.role === 'admin'
                          ? language === 'en'
                            ? 'Super Admin'
                            : 'Quản lý cấp I'
                          : language === 'en'
                          ? 'Operator'
                          : 'Quản trị viên'}
                        )
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              <Box paddingLeft={isCollapsed ? undefined : '8%'}>
                {/* {!props.loggedIn && (
                  <Item title={strLang.PUBLIC} to='/public' icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
                )}
                {props.loggedIn && ( */}
                <Item title={strLang.DASHBOARD} to='/dashboard' icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
                {/* )} */}
                <Item title={strLang.INFO} to='/info' icon={<InfoOutlinedIcon />} selected={selected} setSelected={setSelected} />
                {!isCollapsed && props.loggedIn && (
                  <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                    {strLang.DATA}
                  </Typography>
                )}
                <Item
                  title={strLang.KNOWLEDGE}
                  to='/ridesafe'
                  icon={<SchoolOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {props.loggedIn && (
                  <>
                    <Item title={strLang.MAP} to='/map' icon={<MapOutlinedIcon />} selected={selected} setSelected={setSelected} />
                    <Item
                      title={strLang.CAMPAIGN}
                      to='/campaign'
                      icon={<ReceiptOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item title={strLang.ROADS} to='/roads' icon={<RoadIcon />} selected={selected} setSelected={setSelected} />
                    <Item title={'Pathfinder'} to='/pathfinder' icon={<RouteIcon />} selected={selected} setSelected={setSelected} />
                  </>
                )}
                {!isCollapsed && (
                  <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                    {strLang.OTHER}
                  </Typography>
                )}
                {props.loggedIn && (
                  <Item title={strLang.TEAM} to='/team' icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected} />
                )}
                <Item title={strLang.FAQ} to='/faq' icon={<HelpOutlineOutlinedIcon />} selected={selected} setSelected={setSelected} />
              </Box>
            </Menu>
          </ProSidebar>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
