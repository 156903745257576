import React, { useEffect, useRef } from 'react';
import { MapContainer, LayersControl, LayerGroup, Polyline, CircleMarker, useMap } from 'react-leaflet';
import Box from '@mui/material/Box';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import Basemaps from './Basemaps.jsx';
import SchoolMarker from './SchoolMarker.jsx';
import { phuThienCoord as phuThien } from '../../data/geoCoding.js';

const Cluster = ({ dataArray }) => {
  const { Overlay } = LayersControl;
  // const [coordinates, setCoordinates] = useState([]);
  // const [polylineInfo, setPolylineInfo] = useState(null);

  const timeoutRef = useRef(null);
  const mapRef = useRef();

  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          Lat: school[2],
          Long: school[3],
          address: school[4],
          // ward: `${subarea.name} (${subarea.id})`,
          // district: `${area.name} (${area.id})`,
          // city: `${city.name} (${city.id})`,
          // startDate: school[8], // Initialize start and end dates
          // endDate: school[9],
        }));
      })
    )
  );
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Custom hook to fit the map bounds to all polylines
  const FitBoundsPolylines = ({ polylines }) => {
    const map = useMap();

    useEffect(() => {
      if (polylines.length > 0) {
        const bounds = L.latLngBounds(polylines.flat());
        map.fitBounds(bounds);
      }
    }, [polylines, map]);

    return null;
  };
  const getLatLongOnly = (obj) => {
    let _latLongArr = {};
    Object.keys(obj).map((key) => {
      const obj1 = obj[key];
      if (!_latLongArr[key]) _latLongArr[key] = {};
      Object.keys(obj1).map((key1) => {
        const obj2 = obj1[key1];
        if (obj2.length > 2) {
          if (!_latLongArr[key][key1]) _latLongArr[key][key1] = [];
          _latLongArr[key][key1] = obj2.map((e) => [e.Lat.toFixed(4), e.Long.toFixed(4)]);
        }
      });
    });
    // console.log(_latLongArr);
    return _latLongArr;
  };
  const latLongOnly = getLatLongOnly(dataArray);
  // const combineLatLong2 = (arr) => {
  //   const latLongArr = [];
  //   arr.forEach((e) => {
  //     latLongArr.push([e.Lat, e.Long]);
  //   });
  //   return latLongArr;
  // };
  const combinedLatLong = (data) => {
    const latLong = {};
    Object.keys(data).forEach((key) => {
      if (!latLong[key]) {
        latLong[key] = [];
      }
      data[key].forEach((e) => {
        latLong[key].push([Number.parseFloat(e.Lat).toFixed(5), Number.parseFloat(e.Long).toFixed(5)]);
      });
    });
    return latLong;
  };
  const combinedData = (data) => {
    const combinedData = {};

    for (const entity in data) {
      for (const date in data[entity]) {
        if (!combinedData[date]) {
          combinedData[date] = [];
        }
        combinedData[date] = combinedData[date].concat(data[entity][date]);
      }
    }
    return combinedData;
  };

  const _combinedData = combinedData(dataArray);
  const _combinedLatLong = combinedLatLong(_combinedData);

  return (
    <Box width={'100%'} height={'100%'} id='map'>
      <MapContainer
        style={{ borderRadius: '10px' }}
        center={phuThien.center}
        zoom={12}
        minZoom={10}
        maxZoom={18}
        maxBoundsViscosity={1.0}
        maxBounds={
          JSON.parse(localStorage.getItem('user')).username === process.env.REACT_APP_SUPER_ADMIN
            ? null
            : L.latLngBounds(phuThien.sW, phuThien.nE)
        }
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}>
        <LayersControl name='Cluster map' position='topright'>
          {Object.keys(dataArray).map((key) => {
            const dataArray1 = dataArray[key];
            return Object.keys(dataArray1).map((key1) => {
              return (
                <Overlay name={`Device ${key} Day ${key1}`} key={key1} checked>
                  <LayerGroup>
                    <Polyline positions={latLongOnly[key][key1]} pathOptions={{ color: 'grey', opacity: 0.5, weight: 3 }} />
                    {latLongOnly[key][key1].map((itm, idx) => {
                      return (
                        <CircleMarker
                          key={idx}
                          center={itm}
                          color={'grey'}
                          radius={3}
                          pathOptions={{ weight: 1, opacity: 0.5, color: 'black', fillOpacity: 0.3 }}
                        />
                      );
                    })}
                  </LayerGroup>
                </Overlay>
              );
            });
          })}

          {Object.keys(_combinedData).map((key, index2) => (
            <Overlay name={`Cluster map`} key={`Cluster-${index2}`} checked>
              <MarkerClusterGroup chunkedLoading maxClusterRadius={40} key={index2}>
                {_combinedData[key].map((point, index3) => {
                  return (
                    <CircleMarker
                      key={index3}
                      center={[point.Lat, point.Long]}
                      opacity={0.5}
                      color={'black'}
                      radius={2.5}
                      pathOptions={{ stroke: false }}></CircleMarker>
                  );
                })}
              </MarkerClusterGroup>
            </Overlay>
          ))}

          <Overlay name={'Các trường THCS'} key={'schools'} checked>
            <LayerGroup>
              {schools.map((school, idx) => (
                <SchoolMarker key={idx} position={[school.Lat, school.Long]} address={school.address} name={school.schoolName} />
              ))}
            </LayerGroup>
          </Overlay>
          <Basemaps />
        </LayersControl>
        <FitBoundsPolylines polylines={Object.values(_combinedLatLong)} />
      </MapContainer>
    </Box>
  );
};

export default Cluster;
