import React, { Fragment, useEffect, useRef, useState } from 'react';
import { MapContainer, LayersControl, LayerGroup, Polyline, Polygon, Popup, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useLanguage, langTokens } from '../../language';
import Basemaps from './Basemaps.jsx';
import SchoolMarker from './SchoolMarker.jsx';
import calculateBoundingRectangles from '../mapAnalyzingFunctions.jsx';
import {
  route_to_1 as routes1,
  route_to_2 as routes2,
  route_to_3 as routes3,
  route_to_5 as routes5,
  route_to_6 as routes6,
  route_to_7 as routes7,
  route_to_8 as routes8,
  route_to_9 as routes9,
  route_to_10 as routes10,
  route_to_11 as routes11,
} from '../../data/geoCoding.js';
import { phuThienCoord as phuThien, densityColor } from '../../data/geoCoding.js';

const PlainMap = ({ schoolId, roadData, viewMode }) => {
  // console.log('Plain Map');
  const { language } = useLanguage();
  const strLang = langTokens(language).plainmap;
  const { Overlay } = LayersControl;
  const mapRef = useRef();
  const [polylineInfo, setPolylineInfo] = useState(undefined);
  const popupRef = useRef(null);
  const width = 30; // 30 meters width
  const privateMode = viewMode === 'private' ? true : false;

  const routes = [routes1, routes2, routes3, null, routes5, routes6, routes7, routes8, routes9, routes10, routes11];
  const _routes = routes[schoolId - 1];
  const allSchools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          Lat: school[2],
          Lon: school[3],
          coordinate: `[${school[2]}, ${school[3]}]`,
          address: school[4],
          ward: `${subarea.name} (${subarea.id})`,
          district: `${area.name} (${area.id})`,
          city: `${city.name} (${city.id})`,
          startDate: school[8], // Initialize start and end dates
          endDate: school[9],
        }));
      })
    )
  );
  const school = allSchools.find((school) => school.id === schoolId);

  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.on('click', () => {
        // Handle click event
      });

      const timeoutId = setTimeout(() => {
        popupRef.current.close();
        setPolylineInfo(undefined);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  useEffect(() => {
    setPolylineInfo(undefined);
  }, []);

  const handlePolylineClick = (e) => {
    const polyline = e.target;
    const latlng = e.latlng; // Get the coordinates of the clicked point on the polyline
    const id = polyline.options.id;
    const color = polyline.options.color;

    // Access road data using the clicked polyline's ID
    setPolylineInfo({
      id,
      color,
      latlng,
    });
  };
  const combinedLatLong = (data) => {
    const latLong = {};
    Object.keys(data).forEach((key) => {
      if (!latLong[key]) {
        latLong[key] = [];
      }
      data[key].forEach((e) => {
        latLong[key].push([Number.parseFloat(e.Lat).toFixed(5), Number.parseFloat(e.Long).toFixed(5)]);
      });
    });
    return latLong;
  };
  const combinedData = (data) => {
    const combinedData = {};

    for (const entity in data) {
      for (const date in data[entity]) {
        if (!combinedData[date]) {
          combinedData[date] = [];
        }
        combinedData[date] = combinedData[date].concat(data[entity][date]);
      }
    }
    return combinedData;
  };

  const _combinedData = combinedData(_routes);
  const _combinedLatLong = combinedLatLong(_combinedData);

  const FitBoundsPolylines = ({ polylines }) => {
    const map = useMap();

    useEffect(() => {
      if (polylines.length > 0) {
        const bounds = L.latLngBounds(polylines.flat());

        map.fitBounds(bounds);
      }
    }, [polylines, map]);

    return null;
  };

  const convertToLatLongObj = (oldObject) => {
    const newObject = {};
    for (const key in oldObject) {
      if (Object.hasOwnProperty.call(oldObject, key)) {
        const array = oldObject[key];
        newObject[key] = array.map((item) => [item.Lat, item.Long]);
      }
    }
    return newObject;
  };
  const _routesArray = convertToLatLongObj(_routes);
  console.log('roadData', roadData);

  return (
    <Box width={'100%'} height={'100%'} id='map'>
      <MapContainer
        style={{ borderRadius: '10px' }}
        center={phuThien.center}
        zoom={13}
        minZoom={10}
        maxZoom={18}
        maxBoundsViscosity={1.0}
        maxBounds={
          JSON.parse(localStorage.getItem('user')).username === process.env.REACT_APP_SUPER_ADMIN
            ? null
            : L.latLngBounds(phuThien.sW, phuThien.nE)
        }
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}>
        <LayersControl position='topright'>
          <Overlay name={strLang.SCHOOL_NO + ' ' + schoolId + ' - ' + school.schoolName} key={'school-marker'} checked>
            <SchoolMarker key={schoolId} position={[school.Lat, school.Lon]} address={school.address} name={school.schoolName} />
          </Overlay>

          {Object.keys(_routesArray).length > 0 && (
            <>
              {privateMode && (
                <>
                  <Overlay name={strLang.SCHOOL_ROUTES + ' ' + schoolId} key={'routes-' + schoolId} checked>
                    <LayerGroup>
                      {Object.keys(_routesArray).map((key, idx) => {
                        return (
                          <Fragment key={idx}>
                            <Polyline positions={_routesArray[key]} color='gray' />
                            <Marker
                              position={_routesArray[key][_routesArray[key].length - 1]}
                              icon={L.divIcon({ className: 'routeNumber', html: key, iconSize: null })}
                            />
                          </Fragment>
                        );
                      })}
                    </LayerGroup>
                  </Overlay>
                  {roadData !== null && (
                    <Overlay name={strLang.SCHOOL_ROADS + ' ' + schoolId} key={'roads-' + schoolId} checked>
                      <LayerGroup>
                        {calculateBoundingRectangles(roadData, width).map((road, index) => (
                          <Polygon
                            key={index}
                            positions={road.corners} // Passing the corner coordinates to the Polygon
                            color='blue'
                            weight={1}
                          />
                        ))}
                      </LayerGroup>
                    </Overlay>
                  )}
                </>
              )}
              {roadData !== null && (
                <Overlay name={strLang.SCHOOL_RESULTS + ' ' + schoolId} key={'result-' + schoolId} checked={!privateMode}>
                  <LayerGroup>
                    {roadData.map((road, idx) => {
                      // console.log(_roads[road]);
                      return (
                        <Fragment key={road.id}>
                          <Polyline
                            positions={road.plot}
                            pathOptions={{ opacity: 0.95, weight: 9.5 }}
                            color={densityColor[road.color - 1]}
                            id={idx}
                            interactive={true} // Enable click events on the polyline
                            eventHandlers={{
                              click: handlePolylineClick,
                              mouseout: () => {
                                setPolylineInfo(undefined);
                              },
                            }}
                          />
                          {polylineInfo && (
                            <Popup position={polylineInfo.latlng}>
                              <Typography variant='h5' fontWeight={'bold'} mb={'0.3em'}>
                                {polylineInfo.id + 1} - {roadData[polylineInfo.id].road}
                              </Typography>
                              <p style={{ margin: 0 }}>Mật độ: {roadData[polylineInfo.id].ratio} %</p>
                              <p style={{ margin: 0 }}>Số lượng: {roadData[polylineInfo.id].count}</p>
                            </Popup>
                          )}
                        </Fragment>
                      );
                    })}
                  </LayerGroup>
                </Overlay>
              )}
            </>
          )}
          <Basemaps />
        </LayersControl>
        <FitBoundsPolylines polylines={Object.values(_combinedLatLong)} />
      </MapContainer>
    </Box>
  );
};

export default PlainMap;
