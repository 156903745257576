import React, { useEffect, useState } from 'react';
import { roads_11 as roads, route_to_11 as routes } from '../../data/geoCoding';
import {} from '../../data/geoCoding';
import { Box } from '@mui/material';
import { countIntersections } from '../../components/mapAnalyzingFunctions.jsx';

const Misc = () => {
  const quarters = ['05:00-08:00', '10:00-12:00', '12:00-14:00', '16:00-19:00'];
  const [roadData, setRoadData] = useState(null);
  const data = JSON.parse(localStorage.getItem('initData'))?.filteredObj;
  const currentSchool = JSON.parse(localStorage.getItem('currentSchool')) || null;
  const allRoads = JSON.parse(localStorage.getItem('roadData')) || null;

  const newRoads = allRoads;
  const width = 30; // 30 meters width
  const [results, setResults] = useState([]);

  useEffect(() => {
    const _results = {};
    quarters.forEach((quarter) => {
      if (!_results[quarter]) _results[quarter] = {};
      // console.log('quarter', quarter);
      // let totalIntersectionCount = 0;
      Object.keys(data).map((day) => {
        // day: e.g. 2024-09-15
        if (!data[day][quarter]) data[day][quarter] = {};
        _results[quarter][day] = countIntersections(data[day][quarter], newRoads);
        _results[quarter][day].count = Object.keys(data[day][quarter]).length;
        // totalIntersectionCount += _results[quarter][day].total;
        // const totalSum1 = Object.values(_results[quarter][day]).reduce((acc, curr) => acc + curr.total, 0);
      });
      // _results[quarter].totalCount = totalIntersectionCount;
    });
    console.log(_results);
    setResults(_results);
  }, []);

  const ranges = [
    { min: 0, max: 20 },
    { min: 21, max: 40 },
    { min: 41, max: 60 },
    { min: 61, max: 80 },
    { min: 81, max: 100 },
  ];
  const [selectedRanges, setSelectedRanges] = useState([]);

  const handleRangeChange = (rangeIndex) => {
    const updatedRanges = [...selectedRanges];
    if (updatedRanges.includes(rangeIndex)) {
      updatedRanges.splice(updatedRanges.indexOf(rangeIndex), 1);
    } else {
      updatedRanges.push(rangeIndex);
    }
    setSelectedRanges(updatedRanges);
  };

  const filteredData = roads.filter((roadSegment) => {
    return selectedRanges.some((rangeIndex) => {
      const { min, max } = ranges[rangeIndex];
      return roadSegment.ratio >= min && roadSegment.ratio <= max;
    });
  });

  function RoadSegment({ roadSegment }) {
    return (
      <div>
        <h2>Road Segment {roadSegment.id}</h2>
        <p>Road: {roadSegment.road}</p>
        {/* Render central and plot coordinates using a mapping function or a dedicated component */}
        <p>Count: {roadSegment.count}</p>
        <p>Ratio: {roadSegment.ratio}%</p>
      </div>
    );
  }

  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <div>
        <h3>
          <strong>
            Road Boundary Checker - School name: {currentSchool.schoolName}. School id: {currentSchool.id}
          </strong>
        </h3>
        <ul>
          <li>
            <Box display={'flex'} fontWeight={'bold'}>
              <Box width={'10em'}>Khung giờ</Box>
              <Box width={'15em'}>Tuyến đường</Box>
              <Box width={'7em'} textAlign={'center'}>
                Ngày 1
              </Box>
              <Box width={'7em'} textAlign={'center'}>
                Ngày 2
              </Box>
              <Box width={'7em'} textAlign={'center'}>
                Ngày 3
              </Box>
              <Box width={'7em'} textAlign={'center'}>
                Ngày 4
              </Box>
              <Box width={'7em'} textAlign={'center'}>
                Ngày 5
              </Box>
              <Box width={'5em'} textAlign={'center'}>
                Tổng
              </Box>
            </Box>
          </li>
          <li>
            <Box display={'flex'} borderBottom={'solid 1px white'}>
              <Box width={'25em'}></Box>
              {/* <Box width={'4em'} textAlign={'center'}>
                SL
              </Box> */}
              <Box width={'3em'} textAlign={'center'}>
                Tỉ lệ %
              </Box>
              {/* <Box width={'4em'} textAlign={'center'}>
                SL
              </Box> */}
              <Box width={'3em'} textAlign={'center'}>
                Tỉ lệ %
              </Box>
              {/* <Box width={'4em'} textAlign={'center'}>
                SL
              </Box> */}
              <Box width={'3em'} textAlign={'center'}>
                Tỉ lệ %
              </Box>
              {/* <Box width={'4em'} textAlign={'center'}>
                SL
              </Box> */}
              <Box width={'3em'} textAlign={'center'}>
                Tỉ lệ %
              </Box>
              {/* <Box width={'4em'} textAlign={'center'}>
                SL
              </Box> */}
              <Box width={'3em'} textAlign={'center'}>
                Tỉ lệ %
              </Box>
              <Box width={'5em'} fontWeight={'bold'} textAlign={'center'}>
                Số
              </Box>
            </Box>
          </li>
          {Object.keys(results).map((quarter) => (
            <li key={quarter}>
              <Box display={'flex'} borderBottom={'solid 1px white'}>
                <Box width={'10em'}>{quarter}</Box>
                <Box display={'flex'} flexDirection={'column'}>
                  {allRoads.map((road) => {
                    let accu = 0;
                    let maxCount = 0;
                    let dayCount = 0;
                    return (
                      <Box display={'flex'}>
                        <Box width={'15em'}>{road.road}:</Box>
                        {Object.keys(results[quarter]).map((day, idx) => {
                          if ((0 < idx) & (idx < 4)) {
                            let z = results[quarter][day];
                            let x = z[road.road].count;
                            accu = accu + x;
                            maxCount = z.maxCount;
                            dayCount = z.count;
                            return (
                              <>
                                {/* <Box width={'4em'} textAlign={'center'}> */}
                                {/* {results[quarter][day][road.road].count}/{results[quarter][day].count} */}
                                {/* {x} */}
                                {/* </Box> */}
                                {/* <Box width={'3em'} color={'orange'} textAlign={'center'}>
                                  {/* {Math.round((results[quarter][day][road.road].count / results[quarter][day].count) * 100, 2)}% */}
                                {/* {Math.round((x / maxCount) * 100, 4)}% */}
                                {/* </Box> */}
                                <Box width={'10em'} color={'orange'} textAlign={'center'}>
                                  {/* {Math.round((results[quarter][day][road.road].count / results[quarter][day].count) * 100, 2)}% */}
                                  {x}/{dayCount} = {Math.round((x / dayCount) * 100, 4)}%
                                </Box>
                              </>
                            );
                          }
                        })}
                        <Box width={'5em'} textAlign={'center'} color={'yellow'}>
                          {accu}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              {/* <strong>{road}</strong>: {results[road].count} intersections. Ratio:{' '}
              {(results[road].count / Object.keys(routes).length) * 100} % [
              {results[road].routeIndices.map((index) => (
                <span key={index}>{index + 1}, </span>
              ))}
              ] */}
            </li>
          ))}
        </ul>
      </div>
      {/*    
      <div>
        /* Checkbox list for selecting ranges
        <ul>
          <p>{selectedRanges}</p>
          {ranges.map((range, index) => (
            <li key={index}>
              <input type='checkbox' checked={selectedRanges.includes(index)} onChange={() => handleRangeChange(index)} />
              {`Range ${range.min}-${range.max}`}
            </li>
          ))}
        </ul>

        /* Render filtered data
        <div>
          {filteredData.map((roadSegment, index) => (
            <RoadSegment key={index} roadSegment={roadSegment} />
          ))}
        </div>
      </div>
      */}
    </Box>
  );
};

export default Misc;
